import React from 'react'

import Helmet from 'react-helmet'
import { Location } from '@reach/router'
import PropTypes from 'prop-types'

import Layout from 'components/Layout'
import Footer from 'components/Landings/Footer'
import EventDetails from './components/_EventDetails'
import CreateEvent from './components/_CreateEvent'
import CreateEventForm from './components/_CreateEventForm'
import ShareEvent from './components/_ShareEvent'

import EventStore from './_store'

import cx from 'classnames'
import { getUrlParameter } from 'utils/parse-querystring'
import { FullPageLoader } from 'components/Loader'

const meta = {
  title: 'Event Flight Watcher',
  description:
    'Never miss a cheap flight deal. See and get notified of 100% of all cheap economy class flight deals to your dream destinations across the world.·',
  image:
    'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1544482082/assets/meta/Prime.jpg',
  logo:
    'https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png',
  url: 'https://www.staycircles.com/events'
}

class EventsContent extends React.PureComponent {
  state = { hidden: true }

  showPage = () => this.setState({ hidden: false })

  getEvent = () => {
    const { eventID, store } = this.props

    if (eventID) {
      store.getEvent(eventID)
    }
  }

  componentDidMount () {
    if (this.props.eventID) {
      this.getEvent()
    } else {
      this.props.store.clearTimer()
      this.showPage()
    }
  }

  componentDidUpdate ({ eventID }) {
    if (!eventID) {
      this.props.store.clearTimer()
    }
    if (this.props.eventID && eventID !== this.props.eventID) {
      this.getEvent()
    }

    if (this.state.hidden && this.props.eventID) {
      const { loading, event } = this.props.store.state

      if (!loading && event.eventID) {
        this.setState({ hidden: false })
      }
    }
  }

  componentWillUnmount () {
    this.props.store.clearTimer()
  }

  render () {
    if (this.state.hidden) {
      const { eventID } = this.props
      const { loading, event, error } = this.props.store.state
      if (loading) {
        return <FullPageLoader />
      }
      if (eventID && !loading && error && Object.keys(event).length === 0) {
        return (
          <div className='flex flex-column flex-column-m justify-center'>
            <p className='f3 lh-title mv4 tc'>
              Sorry, this page isn't available.
            </p>
            <p className='tc f6'>
              The link you followed may be broken.{' '}
              <a href='/events'>Back to events.</a>
            </p>
          </div>
        )
      }
      return null
    }

    const { eventID } = this.props
    const ChildComponent = eventID ? EventDetails : CreateEventForm

    return (
      <>
        <div
          className={cx(
            'flex flex-column flex-row-ns flex-column-m justify-center',
            {
              mt5: !eventID,
              mt3: !!eventID
            }
          )}
          style={{ color: '#153C6B' }}
        >
          <CreateEvent eventID={eventID} />
          <ChildComponent {...this.props} showPage={this.showPage} />
          <ShareEvent />
        </div>

        <Footer marginTop />
      </>
    )
  }
}

EventsContent.propTypes = {
  eventID: PropTypes.string
}

class EventsIndex extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = { ...meta, hidden: true }
  }

  setMeta = meta => this.setState({ ...meta })

  render () {
    const { description, logo, title, image, url } = this.state

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>

          <meta name='description' content={description} />
          <meta name='image' content={logo} />

          <meta itemProp='name' content={title} />
          <meta itemProp='description' content={description} />
          <meta itemProp='image' content={logo} />

          <meta name='twitter:card' content='summary' />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:description' content={description} />

          <meta name='og:title' content={title} />
          <meta name='og:description' content={description} />
          <meta name='og:image' content={image} />
          <meta name='og:url' content={url} />
          <meta name='og:site_name' content='StayCircles' />
          <meta name='og:type' content='website' />
        </Helmet>

        <Layout className='overflow-x-hidden'>
          <EventStore>
            {store => (
              <Location>
                {({ location, navigate }) => {
                  const eventID = getUrlParameter(location, 'id')

                  return (
                    <React.Fragment>
                      <EventsContent
                        setMeta={this.setMeta}
                        {...store.state}
                        {...{ location, navigate, eventID, store }}
                      />
                    </React.Fragment>
                  )
                }}
              </Location>
            )}
          </EventStore>
        </Layout>
      </React.Fragment>
    )
  }
}

export default EventsIndex
