import { create as createApi } from 'apisauce'

import { getDefaultResponse } from 'utils/api'
import config from 'config'

const efwApi = createApi({
  baseURL: config.EFW_URL,
  headers: {
    'API-SECRET': config.EFW_SECRET,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export const getEvent = eventID => {
  return getDefaultResponse(efwApi.get, '/events', {
    eventID
  })
}

export const checkEventID = eventID => {
  return getDefaultResponse(efwApi.get, '/events/check', {
    eventID
  })
}

export const createEvent = event => {
  return getDefaultResponse(efwApi.post, '/events', event)
}

export const addOrigin = ({ origin, eventID }) => {
  return getDefaultResponse(efwApi.put, '/events', {
    eventID,
    origins: [origin]
  })
}
