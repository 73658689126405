import React from 'react'

import PlacesAutocomplete from 'react-places-autocomplete'

import { noop, stubTrue, throttle } from 'lodash'
import styled from 'styled-components'

import Icon from 'components/Icon'
import { TextInput, FloatingLabel } from 'components/Input'

import api from 'utils/api'
// import { geocodeAddress } from 'utils/location'
import logger from 'utils/logger'
import zIndex from 'styles/zIndex'
import config from 'config'

const baseURLv2 = config.API_URL.replace('v1', 'v2')

const searchOptions = {
  types: ['(cities)']
}

const getAirportsApi = async (apiPath, term, lat, long, page = 1) => {
  const params = {
    page,
    term,
    lat,
    long
  }

  return api.get(apiPath, params, {
    baseURL: baseURLv2
  })
}

class AirportPicker extends React.Component {
  static defaultProps = {
    hideIcon: false,
    excludeFilter: stubTrue,
    onSelect: noop,
    placeholder: 'Search Aiport IATA',
    initialValue: '',
    floatingLabel: true,
    iconName: 'search',
    apiPath: '/airports'
  }

  constructor (props) {
    super(props)

    this.state = {
      address: props.initialValue,
      searching: false,
      placeID: null,
      results: []
    }

    this.findAirport = throttle(this.findAirport, 500, {
      leading: false,
      trailing: true
    })
  }

  // findAirport = (address, placeID) => {
  //   geocodeAddress(address, placeID)
  //     .then(results => this.handleGeocodeResult(results, placeID))
  //     .catch(logger.captureException)
  // }
  findAirport = term => {
    this.handleAirportResult(term)
  }

  handleChange = address => {
    if (this.props.onChange) {
      this.props.onChange(address)
    }
    this.setState({ address, searching: true })
  }

  handleSelect = airport => {
    const callback = ({ reset, address }) => {
      if (address !== undefined) {
        this.setState({ address })
      }

      if (reset) {
        this.setState({
          results: []
        })
      }
    }

    this.props.onSelect(airport, callback)
  }

  getAirports = suggestions => {
    // const placeID = get(suggestions, '0.placeId')
    const { results, searching, address } = this.state
    const { excludeFilter } = this.props

    if (searching && address.length > 0) {
      // const address = suggestions[0].description
      this.findAirport(this.state.address)
      this.setState({ searching: false })
    }

    if (results && results.length > 0) {
      return (
        <SuggestionsContainer className='shadow-5'>
          {this.state.results.filter(excludeFilter).map(airport => {
            const name = airport.regionalCity
              ? airport.country
                ? `${airport.regionalCity.name}, ${airport.country.name}`
                : airport.regionalCity.name
              : airport.name
            return (
              <Suggestion
                key={airport.id}
                className='truncate'
                onClick={() => this.handleSelect(airport)}
              >
                <AirplaneIcon />
                <span className='mr2 f4 truncate mb0'>{name}</span>
                <span className='b f4 truncate black-60'>
                  {airport.iataCode}
                </span>
              </Suggestion>
            )
          })}
        </SuggestionsContainer>
      )
    } else {
      return null
    }
  }

  // handleGeocodeResult = async (results, placeID) => {
  //   let place = results[0]

  //   if (!place.geometry) {
  //     return
  //   }

  //   const { lat, lng } = get(place, 'geometry.location', {})

  //   if (!lat || !lng) return

  //   const [error, data] = await getAirportsApi(this.props.apiPath, lat(), lng())

  //   if (error) {
  //     return this.setState({
  //       results: [],
  //       error
  //     })
  //   }

  //   this.setState({
  //     results: data.airports,
  //     placeID
  //   })
  // }

  handleAirportResult = async term => {
    const [error, data] = await getAirportsApi(this.props.apiPath, term)

    if (error) {
      return this.setState({
        results: [],
        error
      })
    }

    this.setState({
      results: data.airports
    })
  }

  // handleFocus = e => {
  //   const { onFocus } = this.props
  //   onFocus && onFocus(e)
  //   this.setState({ focus: true })
  // }

  // handleBlur = e => {
  //   const { onBlur } = this.props
  //   onBlur && onBlur(e)
  //   this.setState({ focus: false })
  // }

  render () {
    const {
      hideIcon,
      name,
      placeholder,
      floatingLabel,
      iconName,
      onIconClick
    } = this.props
    const { focus, address } = this.state

    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onError={logger.captureException}
        searchOptions={searchOptions}
        shouldFetchSuggestions={false}
      >
        {({ getInputProps, suggestions }) => (
          <div className='w-100 relative'>
            <Container floatingLabel={floatingLabel}>
              <TextInput
                {...getInputProps({
                  placeholder,
                  className: 'airport-search-input'
                })}
                // onFocus={this.handleFocus}
                // onBlur={this.handleBlur}
              />
              {floatingLabel && (focus || !!address) && (
                <FloatingLabel
                  htmlFor={name}
                  initialPose='hidden'
                  pose='visible'
                  focus={focus}
                >
                  {placeholder}
                </FloatingLabel>
              )}

              {!hideIcon && (
                <RightIcon
                  floatingLabel={floatingLabel}
                  name={iconName}
                  onClick={onIconClick}
                />
              )}
            </Container>
            {this.getAirports(suggestions)}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

export const Container = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${props => (props.floatingLabel ? '5px' : 0)};
  display: flex;
  flex-direction: column;
`

export const RightIcon = styled(Icon).attrs({
  height: '1.125rem'
})`
  position: absolute;
  right: 1.25rem;
  top: ${props => (props.floatingLabel ? 1.5 : 1.25)}rem;
  ${props => props.onClick && 'cursor: pointer;'};
`

const SuggestionsContainer = styled.div`
  position: absolute;
  z-index: ${zIndex.menu};
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  overflow: auto;
  background-color: white;
  border-top: 1px solid rgba(21, 60, 107, 0.5);
  width: 100%;
  margin-bottom: 180px;

  @media screen and (max-width: 1024px) {
    // bottom: 100%;
    top: initial;
  }
`

const AirplaneIcon = styled(Icon).attrs({
  height: '1.5rem',
  name: 'airplane'
})`
  color: #555555;
  display: inline-block;
  margin: -0.35rem 0.75rem 0rem -0.15rem;
`

const Suggestion = styled.div`
  background-color: ${props => (props.active ? '#f0f0f0' : '#ffffff')};
  padding: 12px;
  color: #555555;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default AirportPicker
