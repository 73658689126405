import React from 'react'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Location, navigate } from '@reach/router'

import queryString from 'utils/parse-querystring'

import colors from 'styles/colors'

import Modal from 'components/Modal'

import AirportPicker from './_AirportPicker'

import root from 'utils/windowOrGlobal'

const closeModal = () => {
  navigate(
    root.location.pathname +
      root.location.search.replace('&add_airport=true', '')
  )
}

class AddAirportModal extends React.Component {
  static defaultProps = {
    origins: [],
    addOrigin: noop
  }

  render () {
    const { origins, addOrigin } = this.props
    return (
      <Location>
        {({ location }) => {
          const query = queryString(location.search)
          const show = !!get(query, 'add_airport')

          return (
            <Modal
              backgroundColor={colors.lightBlueI}
              isOpen={show}
              onRequestClose={closeModal}
              contentLabel='Add Your Airport'
            >
              <p style={{ color: colors.darkestBlue }}>Add Airport</p>
              <div style={{ backgroundColor: 'white', borderRadius: 10 }}>
                <AirportPicker
                  floatingLabel={false}
                  onSelect={airport => {
                    addOrigin(airport.iataCode)
                    closeModal()
                  }}
                  excludeFilter={airport => !origins.includes(airport.iataCode)}
                />
              </div>
            </Modal>
          )
        }}
      </Location>
    )
  }
}

export default AddAirportModal
