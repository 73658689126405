import React, { Fragment } from 'react'

import get from 'lodash/get'

import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { navigate } from 'gatsby'

import styled from 'styled-components'
import { required } from 'utils/field-validators'

import colors from 'styles/colors'

import Icon from 'components/Icon'
import { Button } from 'components/Landings/CheapFlightCard'
import Input, { TextLabel, ErrorText } from 'components/Input'
import AirportPicker from './_AirportPicker'
import { DateRangePicker } from 'react-date-range'

import { addDays, format, parse, differenceInCalendarDays } from 'date-fns'

import root from 'utils/windowOrGlobal'

const DATE_FORMAT = 'yyyy-MM-dd'

const goToNewEvent = ({ eventID }) =>
  navigate(`${root.location.pathname}?id=${eventID}`)

// const getEventIDFromTitle = title => title.match(/[0-9a-zA-Z-_]+/g).join('-')

// const formatURL = state =>
//   state && typeof state.value === 'string'
//     ? getEventIDFromTitle(state.value)
//     : ''

const today = new Date()

function setDateFields ({ startDate, endDate }, fields) {
  if (!startDate) {
    startDate = endDate || today
  }

  if (!endDate) {
    endDate = startDate
  }

  fields.update(0, format(startDate, DATE_FORMAT, today))

  if (startDate.valueOf() === endDate.valueOf()) {
    fields.update(1, format(startDate, DATE_FORMAT, today))
  } else {
    fields.update(1, format(addDays(startDate, 1), DATE_FORMAT, today))
  }

  if (differenceInCalendarDays(endDate, startDate) > 1) {
    fields.update(2, format(addDays(startDate, 2), DATE_FORMAT, today))
  } else {
    fields.remove(2)
  }
}

function getSelectionRange (fields) {
  if (get(fields, 'value.length', 0) === 0) {
    return {
      startDate: today,
      endDate: today,
      key: 'selection'
    }
  } else {
    const startDate = parse(fields.value[0], DATE_FORMAT, today)
    const endDate = parse(
      fields.value[fields.value.length - 1],
      DATE_FORMAT,
      today
    )

    if (startDate.valueOf() > endDate.valueOf()) {
      return {
        startDate,
        endDate: startDate,
        key: 'selection'
      }
    }

    return {
      startDate,
      endDate,
      key: 'selection'
    }
  }
}

class CreateEventForm extends React.PureComponent {
  handleSubmit = async event => {
    // event.eventID = getEventIDFromTitle(event.title)

    const result = await this.props.store.createEvent(event)
    if (result && result.success) {
      goToNewEvent(result.event)
    }
    return result
  }

  render () {
    return (
      <div className='ma3'>
        <Container className='pv4 ph2 ph4-ns mw8 br3 center'>
          <Form
            onSubmit={this.handleSubmit}
            mutators={{
              ...arrayMutators
            }}
            initialValues={{
              origins: ['New York - JFK']
            }}
            render={({
              handleSubmit,
              submitting,
              invalid,
              submitError,
              form
            }) => (
              <form
                onSubmit={handleSubmit}
                className='center flex flex-column items-center w-100 w-90-ns mv4'
              >
                <h2 className='black-80 f2 fw4 lh-title tc w-100'>
                  Create Event Flight Watcher
                </h2>

                <Input
                  LabelComponent={Label}
                  name='title'
                  label='Name event'
                  floatingLabel={false}
                  validate={required}
                  disabled={submitting}
                  // helperText={`staycircles.com/events/${formatURL(
                  //   form.getFieldState('title')
                  // )}`}
                />

                <AirportField
                  iconName='pin-map'
                  name='destination'
                  placeholder='Destination Airport'
                  floatingLabel={false}
                  apiPath='/airports?active_d=true'
                />

                <FieldArray name='origins'>
                  {({ fields }) => (
                    <Fragment>
                      <div className='w-100 mt4 mb2'>
                        <Label className='tl'>Origin Airports</Label>
                        <AddButton
                          className='pointer'
                          onClick={() =>
                            fields.push('New York - JFK', undefined)
                          }
                        >
                          <Icon name='plus' className='h1 mr2' />
                          <span>Airport</span>
                        </AddButton>
                      </div>
                      {fields.map((name, index) => (
                        <div className='w-100 mb3' key={name}>
                          <AirportField
                            apiPath='/airports/all_od'
                            floatingLabel
                            name={name}
                            placeholder={'Origin ' + (index + 1)}
                            iconName='close'
                            onIconClick={() => fields.remove(index)}
                          />
                        </div>
                      ))}
                    </Fragment>
                  )}
                </FieldArray>

                <div className='w-100 ma4'>
                  <Label className='tl'>Add dates</Label>
                  <div className='flex flex-column flex-row-l'>
                    <FieldArray name='departDates'>
                      {({ fields }) => (
                        <Fragment>
                          <div className='w-50-l center mt3'>
                            <TextLabel className='tl'>
                              Depart Dates (maximum of 3)
                            </TextLabel>
                            <DateRangePicker
                              className='mt2 pr2-ns'
                              direction='horizontal'
                              months={1}
                              minDate={today}
                              showDateDisplay={false}
                              showSelectionPreview={false}
                              ranges={[getSelectionRange(fields)]}
                              onChange={({ selection }) =>
                                setDateFields(selection, fields)
                              }
                            />
                          </div>
                        </Fragment>
                      )}
                    </FieldArray>

                    <FieldArray name='returnDates'>
                      {({ fields }) => (
                        <Fragment>
                          <div className='w-50-l center mt3'>
                            <TextLabel className='tl'>
                              Return Dates (maximum of 3)
                            </TextLabel>
                            <DateRangePicker
                              className='mt2 pl2-ns'
                              direction='horizontal'
                              months={1}
                              minDate={today}
                              showDateDisplay={false}
                              showSelectionPreview={false}
                              ranges={[getSelectionRange(fields)]}
                              onChange={({ selection }) =>
                                setDateFields(selection, fields)
                              }
                            />
                          </div>
                        </Fragment>
                      )}
                    </FieldArray>
                  </div>
                </div>

                {submitError && (
                  <div className='w-100 mt4 mb2'>
                    <ErrorText>{submitError}</ErrorText>
                  </div>
                )}

                <Button
                  disabled={submitting}
                  type='submit'
                  border={`5px solid ${colors.lightBlueII}`}
                  style={{ padding: '18px 36px' }}
                  fontWeight='bold'
                  backgroundColor={colors.lightBlueI}
                  focusBackgroundColor={colors.darkIce}
                  children={submitting ? 'Creating Event...' : 'Create Event'}
                />
              </form>
            )}
          />
        </Container>
      </div>
    )
  }
}

const AddButton = styled.button.attrs({
  type: 'button'
})`
  background: transparent;
  outline: none;
  border: 1px solid slategray;
  color: slategray;
  margin-left: 10px;
  padding: 4px 8px;
  border-radius: 24px;
  font-size: 12px;

  &:hover {
    background: ${colors.blue};
    color: ${colors.white};
    border-color: ${colors.darkerBlue};
  }
`

const Container = styled.div`
  background: rgba(216, 233, 255, 0.5);

  .rdrDefinedRangesWrapper {
    display: none;
  }
`

const Label = styled(TextLabel)`
  font-weight: 600;
`

function AirportField ({ name, placeholder, floatingLabel, ...rest }) {
  return (
    <Field name={name}>
      {props => {
        const { value, onChange, name } = props.input

        return (
          <Fragment>
            {!floatingLabel && (
              <div className='w-100 tl mt3 mb2'>
                <Label
                  className={floatingLabel ? 'dn' : ''}
                  htmlFor={name}
                  children={placeholder}
                />
              </div>
            )}
            <div className='w-100'>
              <AirportPicker
                {...{ name, placeholder, floatingLabel }}
                initialValue={value}
                placeholder={placeholder}
                onSelect={(airport, updateState) => {
                  const address = `${airport.regionalCity.name} - ${airport.iataCode}`
                  onChange(address)
                  updateState({ reset: true, address })
                }}
                // excludeFilter={airport =>
                //   value ? !value.includes(airport.iataCode) : true
                // }
                excludeFilter={() => true}
                {...rest}
              />
            </div>
          </Fragment>
        )
      }}
    </Field>
  )
}

export default CreateEventForm
