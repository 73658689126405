import React from 'react'

import PropTypes from 'prop-types'
import { Container, Subscribe } from 'unstated'

import get from 'lodash/get'
import uniq from 'lodash/uniq'
import formatDate from 'utils/formatDate'

import { FORM_ERROR } from 'final-form'

import root from 'utils/windowOrGlobal'

import * as api from './_api'

class Events extends Container {
  state = {
    loading: false,
    error: null,
    event: {},
    validEventID: false,
    timer1: null,
    timer2: null
  }

  setItem = item =>
    this.setState({
      ...item,
      loading: false,
      error: null
    })

  setError = error =>
    this.setState({
      loading: false,
      error
    })

  setLoading = () =>
    this.setState({
      loading: true,
      error: null
    })

  checkEventID = async eventID => {
    const [error, data] = await api.checkEventID(eventID)

    this.setLoading()

    if (error) {
      await this.setError(error)

      return { [FORM_ERROR]: error }
    } else {
      await this.setItem({ validEventID: data.valid })
    }

    return data.valid
  }

  getEvent = async eventID => {
    if (this.state.loading) return

    this.setLoading()

    const [error, data] = await api.getEvent(eventID)

    if (error) {
      await this.setError(error)

      return { [FORM_ERROR]: error }
    } else {
      this.checkPrices(data)
      await this.setItem({ event: data })
    }

    return data
  }

  getCompleteEvent = async eventID => {
    const [error, data] = await api.getEvent(eventID)

    if (error) {
      this.getCompleteEvent(eventID)
    } else {
      const { returnDates, departDates, prices } = data
      const totalPrices = returnDates.length * departDates.length * 3
      const hasEmpty = prices.find(flights => flights.length < totalPrices)

      // set event data as soon as possible
      await this.setItem({ event: data })

      // If total prices is not satisifed, continue query
      if (hasEmpty) {
        const timer1 = root.setTimeout(
          () => this.getCompleteEvent(eventID),
          5000
        )
        this.setState({ timer1: timer1 })
      }
    }
  }

  createEvent = async event => {
    if (this.state.loading) return

    const airportRegex = new RegExp(/[A-Z]{3,4}/g)

    if (!event.destination || !event.destination.match(airportRegex)) {
      return { [FORM_ERROR]: 'Destination airport is required' }
    }

    if (
      !event.origins ||
      !Array.isArray(event.origins) ||
      event.origins.length === 0
    ) {
      return { [FORM_ERROR]: 'At least one origin airport is required' }
    }

    event.origins = uniq(event.origins)
      .filter(origin => origin.match(airportRegex))
      .map(origin => origin.match(airportRegex)[0])

    if (event.origins.length < 1) {
      return { [FORM_ERROR]: 'At least one origin airport is required' }
    }

    event.destination = event.destination.match(airportRegex)[0]

    const DATE_FORMAT = 'yyyy-MM-dd'

    event.departDates = (uniq(event.departDates) || []).map(date =>
      formatDate(date, DATE_FORMAT)
    )

    event.returnDates = (uniq(event.returnDates) || []).map(date =>
      formatDate(date, DATE_FORMAT)
    )

    if (event.departDates.length < 1 || event.returnDates.length < 1) {
      return {
        [FORM_ERROR]: 'At least one depart date and one return date required'
      }
    }

    this.setLoading()

    const [error, data] = await api.createEvent(event)

    if (error) {
      await this.setError(error)

      if (error === 'Validation error') {
        return { [FORM_ERROR]: 'Please pick a different event name.' }
      }

      return { [FORM_ERROR]: error }
    } else {
      await this.setItem({ event: data })
      return { success: true, event: data }
    }
  }

  checkPrices = event => {
    const { returnDates, departDates, prices, eventID } = event
    const totalPrices = returnDates.length * departDates.length * 3
    const hasEmpty = prices.find(flights => flights.length < totalPrices)

    if (hasEmpty) {
      const timer2 = root.setTimeout(() => this.getCompleteEvent(eventID), 5000)
      this.setState({ timer2: timer2 })
    }
  }

  addOrigin = async origin => {
    const eventID = get(this.state, 'event.eventID')
    const origins = get(this.state, 'event.origins', [])

    if (!eventID || origins.includes(origin)) return

    const [error, data] = await api.addOrigin({
      origin,
      eventID
    })

    if (error) {
      await this.setError(error)

      return { [FORM_ERROR]: error }
    }

    this.checkPrices(data)
    this.setState({ event: data })

    return data
  }

  clearTimer = () => {
    this.state.timer1 && root.clearTimeout(this.state.timer1)
    this.state.timer2 && root.clearTimeout(this.state.timer2)
    this.setState({ timer1: null, timer2: null })
  }
}

const eventStore = new Events()

export default class EventsContainer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired
  }

  render () {
    return (
      <Subscribe to={[eventStore]}>
        {store => this.props.children(store)}
      </Subscribe>
    )
  }
}
