import React from 'react'

import { IconLink } from 'components/Landings/Footer'
import { Container } from './_CreateEvent'

import styled from 'styled-components'

import root from 'utils/windowOrGlobal'

const ga = (...args) => root && root.ga && root.ga(...args)

class ShareEvent extends React.PureComponent {
  render () {
    return (
      <Container show className='ma3-l center'>
        <div
          className=' flex flex-row pa4 br3'
          style={{ background: 'rgba(216, 233, 255, 0.5)' }}
        >
          <div className='pr2' style={{ flex: '0.55' }}>
            <p className='b' style={{ fontSize: 18 }}>
              Adventure Machine
            </p>
            <p className='b' style={{ fontSize: 14 }}>
              Get the app.
            </p>
            <div className='flex flex-column'>
              <a
                target='_blank'
                href='https://itunes.apple.com/us/app/adventure-machine/id1412862317?ls=1&mt=8'
                onClick={ga(
                  'send',
                  'event',
                  'AM App Store Link',
                  'open',
                  'iOS App Store'
                )}
              >
                <StoreIcon
                  src='https://res.cloudinary.com/staycircles/image/upload/v1572942190/assets/sc_web_landing/download-on-the-app-store-apple_1.png'
                  className='mt2-ns mt3-l mt1 mr2-ns mr1 pr2-l pr1'
                />
              </a>

              <a
                target='_blank'
                href='https://play.google.com/store/apps/details?id=com.staycircles.am.android'
                onClick={ga(
                  'send',
                  'event',
                  'AM App Store Link',
                  'open',
                  'Android Play Store'
                )}
              >
                <StoreIcon
                  src='https://res.cloudinary.com/staycircles/image/upload/v1573449692/assets/sc_web_landing/google-play-badge_1_1.png'
                  className='mt2-ns mt3-l mt1 mr2-ns mr1 pr2-l pr1'
                />
              </a>
            </div>
          </div>
          <div style={{ flex: '0.45' }}>
            <img
              title='Adventure Machine'
              src='https://res.cloudinary.com/staycircles/image/upload/v1572942136/assets/sc_web_landing/Adventure_Machine_Phone_1.png'
            />
          </div>
        </div>

        <div
          className='flex flex-row pa3 br3 mt3 justify-center'
          style={{ background: 'rgba(216, 233, 255, 0.5)' }}
        >
          <IconLink
            link='https://www.facebook.com/AdventureMachineSC'
            name='facebook-square'
            height={24}
            color='#153C6B'
          />
          <IconLink
            link='https://twitter.com/adventuremachSC'
            name='twitter-square'
            height={24}
            color='#153C6B'
          />
          <IconLink
            link='https://instagram.com/adventuremachinesc'
            name='instagram'
            height={24}
            color='#153C6B'
          />
        </div>
      </Container>
    )
  }
}

const StoreIcon = styled.img``

export default ShareEvent
