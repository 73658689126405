import React from 'react'

import styled from 'styled-components'
import colors from '../../styles/colors'
import { navigate } from 'gatsby'
import * as routes from 'config/routes'

const CheapFlightCard = () => {
  return (
    <Container className='ph4-ns ph2 pv4-ns pv2'>
      <Heading>Cheap flight alerts.</Heading>
      <SubHeading> Stay with friends for free.</SubHeading>
      <div className='flex flex-column'>
        <Image
          className='db mv4'
          src='https://res.cloudinary.com/staycircles/image/upload/v1573450889/assets/sc_web_landing/Icons.png'
        />

        <Button
          border={`5px solid ${colors.lightBlueII}`}
          fontWeight='bold'
          backgroundColor={colors.lightBlueI}
          focusBackgroundColor={colors.darkIce}
          children='Get Adventure Machine'
          onClick={() => navigate(routes.GET_ADVENTURE_MACHINE_POPUP)}
        />

        <Button
          className='mt3 dn db-ns'
          children='Log into StayCircles.com'
          onClick={() => navigate(routes.LOGIN)}
        />
      </div>
    </Container>
  )
}

const Heading = styled.h2`
  color: ${colors.darkestBlue};
  margin: 5px;
  line-height: 1;
  @media (min-width: 320px) {
    align-self: center;
  }
`

const SubHeading = styled(Heading)`
  @media (min-width: 320px) {
    align-self: center;
    font-size: 20px;
  }
`

export const Button = styled.button`
  color: ${colors.darkBlueII};
  border: none;
  font-size: 18px;
  border-radius: 6px;
  padding: 20px 25px;
  ${({ border }) => border && `border: ${border}`};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${props =>
    props.backgroundColor
      ? `background-color:${props.backgroundColor}`
      : 'background-color: #FFFFFF'};
  cursor: pointer;
  align-self: center;

  @media (min-width: 1600px) {
    font-size: 20px;
    padding: 20px 35px;
  }

  &:hover,
  &:focus {
    ${props =>
    props.focusBackgroundColor
      ? `background-color:${props.focusBackgroundColor}`
      : 'background-color: #FFFFFF'};
  }
`

Button.defaultProps = {
  type: 'button'
}

const Image = styled.img`
  align-self: center;
  width: 357px;
  max-width: 100%;
  height: auto;
`

const Container = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5%;
  height: 30rem;
  margin-top: auto;
  margin-bottom: auto;
  background-color: white;
  border-radius: 2%;
  @media (max-width: 1600px) {
    height: 25rem;
  }
  @media screen and (max-width: 1120px) {
    margin-top: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    position: static;
    height: auto;
    top: auto;
    left: auto;
    border-radius: inherit;
  }
  @media (min-width: 1600px) {
    left: 9%;
    padding: 4rem;
  }
`

export default CheapFlightCard
