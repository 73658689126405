import React from 'react'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import formatDistance from 'date-fns/formatDistance'
import formatDate from 'utils/formatDate'
import parseISO from 'date-fns/parseISO'

import { SmallLoader } from 'components/InfiniteList'

import styled from 'styled-components'

const FD_TYPES = {
  ECONOMY: 'ECONOMY',
  BUSINESS: 'BUSINESS',
  FIRST_CLASS: 'FIRST_CLASS'
}

const getDarkColorForFdTypes = fdType => {
  if (fdType === FD_TYPES.ECONOMY) {
    return '#43CA82'
  } else if (fdType === FD_TYPES.BUSINESS) {
    return '#F66D59'
  } else if (fdType === FD_TYPES.FIRST_CLASS) {
    return '#1861E6'
  } else {
    throw new Error(
      'this FD_TYPE is not valid/or needs to be added to this func.'
    )
  }
}

const DATE_FORMAT = {
  DAY: 'EEEE',
  DATE: 'MMM d, yyyy'
}

const status = {
  NOT_CACHED: 'NOT_CACHED',
  NO_DEALS: 'NO_DEALS',
  HAS_DEALS: 'HAS_DEALS',
  NO_MATCHING_DEALS: 'NO_MATCHING_DEALS',
  HAS_MATCHING_DEALS: 'HAS_MATCHING_DEALS',
  HAS_MATCHING_LIVE_DEALS: 'HAS_MATCHING_LIVE_DEALS',
  HAS_PARTIAL_MATCHING_LIVE_DEALS: 'HAS_PARTIAL_MATCHING_LIVE_DEALS'
}

const filterDeals = (deals = [], type) => deals.filter(p => p.type === type)

const validateDeals = (deals = []) => deals.filter(p => !!p.price)

const getGFUrl = deal => {
  const { origin, destination, type, departDate, returnDate } = deal
  const flightType =
    {
      PREMIUM_ECONOMY: 'sc:p;',
      BUSINESS: 'sc:b;',
      FIRST_CLASS: 'sc:f;',
      ECONOMY: ''
    }[type] || ''
  const excludeAirlines = deal.excludeAirlines || [
    'F9', // Frontier
    'NK', // Spirit
    'G4', // Allegiant
    'SY' // Sun Country Airlines
  ]

  const excludeAirlinesString = excludeAirlines
    .map(airline => `-${airline}`)
    .join(',')

  return `https://www.google.com/flights?gl=us&hl=en#flt=${origin}.${destination}.${departDate}*${destination}.${origin}.${returnDate};c:USD;e:1;a:${excludeAirlinesString}*${excludeAirlinesString};${flightType}sd:1;t:f`
}

class FlightDetails extends React.PureComponent {
  state = {
    type: FD_TYPES.ECONOMY
  }

  get airportDetails () {
    const {
      props: { meta },
      iata
    } = this
    return get(meta, `airportDetails.${iata}`, {})
  }

  get city () {
    const city = get(this.airportDetails, 'regional_city.name')
    const country = get(this.airportDetails, 'country.name')
    return `${city}, ${country}`
  }

  get iata () {
    return get(this.props, 'iata')
  }

  get flights () {
    return filterDeals(this.props.flights, this.state.type)
  }

  get lastScraped () {
    const cachedAt = get(
      this.flights
        .filter(({ cachedAt }) => cachedAt)
        .sort(
          (a, b) =>
            new Date(b.cachedAt).valueOf() - new Date(a.cachedAt).valueOf()
        ),
      '[0].cachedAt'
    )

    if (cachedAt && !this.hasStatus(status.HAS_PARTIAL_MATCHING_LIVE_DEALS)) {
      return `Updated ${formatDistance(parseISO(cachedAt), new Date())} ago`
    } else {
      return null
    }
  }

  get status () {
    const {
      props: { flights, returnDates, departDates },
      state: { type }
    } = this
    const validDeals = validateDeals(flights)
    const filteredDeals = filterDeals(flights, type)
    const currentDeals = validateDeals(filterDeals(flights, type))

    if (flights.length === 0) {
      return [status.NOT_CACHED]
    }

    if (validDeals.length === 0) {
      return [status.NO_DEALS]
    }

    if (filteredDeals.length === 0) {
      return [status.HAS_DEALS, status.NO_MATCHING_DEALS]
    }

    if (currentDeals.length === 0) {
      return [status.HAS_DEALS, status.HAS_MATCHING_DEALS]
    }

    if (filteredDeals.length === returnDates.length * departDates.length) {
      return [
        status.HAS_DEALS,
        status.HAS_MATCHING_DEALS,
        status.HAS_MATCHING_LIVE_DEALS
      ]
    }

    return [
      status.HAS_DEALS,
      status.HAS_MATCHING_DEALS,
      status.HAS_PARTIAL_MATCHING_LIVE_DEALS
    ]
  }

  renderTable = () => {
    // console.log('-sTATS--', this.status)
    // if (this.hasStatus(status.HAS_PARTIAL_MATCHING_LIVE_DEALS)) {
    //   return (
    //     <React.Fragment>
    //       <SmallLoader />
    //       <p className='i'>Just a minute...</p>
    //     </React.Fragment>
    //   )
    // } else
    if (
      this.hasStatus(status.HAS_MATCHING_LIVE_DEALS) ||
      this.hasStatus(status.HAS_PARTIAL_MATCHING_LIVE_DEALS)
    ) {
      const { returnDates } = this.props

      return (
        <div className='cf ph1-ns'>
          <div className='w-75 cf ph1-ns mb3 flex flex-row justify-between'>
            {sortBy(uniqBy(this.flights, 'departDate'), ['departDate']).map(
              p => (
                <div
                  key={p.departDate}
                  className='f6-ns f7 ph1 b flex-auto tc'
                  style={{ fontSize: '11px' }}
                >
                  <span className='dn di'>
                    {formatDate(p.departDate, DATE_FORMAT.DAY)} <br />
                  </span>
                  {formatDate(p.departDate, DATE_FORMAT.DATE)}
                </div>
              )
            )}
          </div>
          {returnDates
            .sort((a, b) => a - b)
            .map((returnDate, i) => {
              return (
                <div key={returnDate} className='flex flex-row items-center'>
                  <div
                    style={{
                      marginBottom: '-2px',
                      border: `2px solid ${getDarkColorForFdTypes(
                        this.state.type
                      )}`,
                      wordBreak: 'initial'
                    }}
                    className={`fl w-75 cf flex flex-row items-center justify-between overflow-hidden ${
                      i === 0
                        ? 'br-top'
                        : i === returnDates.length - 1
                          ? 'br-bottom'
                          : ''
                    }`}
                  >
                    {sortBy(filter(this.flights, ['returnDate', returnDate]), [
                      'departDate'
                    ]).map((p, i) => (
                      <div
                        key={p.priceID}
                        className='tc fl w-33 pointer flex-auto'
                        style={{
                          borderLeft:
                            i === 0
                              ? 'none'
                              : `2px solid ${getDarkColorForFdTypes(
                                this.state.type
                              )}`
                        }}
                      >
                        <a
                          className='no-underline db'
                          rel='noopener noreferrer'
                          target='_blank'
                          href={p.price ? getGFUrl(p) : '#'}
                          style={{
                            paddingTop: '20px',
                            paddingBottom: '20px'
                          }}
                        >
                          <p
                            className='mb0 f6-ns f5-l'
                            style={{
                              color: `${getDarkColorForFdTypes(
                                this.state.type
                              )}`
                            }}
                          >
                            {p.price
                              ? '$' +
                                parseFloat(
                                  get(p, 'meta.prices[0]', p.price)
                                ).toLocaleString('en')
                              : 'N/A'}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                  <div
                    className='ph1 f6-ns f7 b fl tc w-25'
                    style={{ fontSize: '11px' }}
                  >
                    <span className='di'>
                      {formatDate(returnDate, DATE_FORMAT.DAY)}
                      <br />
                    </span>
                    {formatDate(returnDate, DATE_FORMAT.DATE)}
                  </div>
                </div>
              )
            })}
        </div>
      )
    } else {
      return <p className='i'>No flights currently available</p>
    }
  }

  hasStatus = status => this.status.includes(status)

  render () {
    const { iata, airportDetails } = this
    const city = get(this.airportDetails, 'regional_city.name')

    return (
      <FlightColumn className='fl w-100 w-100-m w-50-l ph2-ns pv2'>
        <Container>
          <div className='flex flex-row'>
            <div style={{ flex: 1 }}>
              <p className='f4 b mb2'>
                {city} ({iata})
              </p>
              <p className='f4'>{airportDetails.name}</p>
              <p className='f6 i'>{this.lastScraped}</p>
            </div>
          </div>
          {this.hasStatus(status.HAS_DEALS) && (
            <div className='flex flex-row mb3'>
              <TypeButton
                active={this.state.type === FD_TYPES.ECONOMY}
                lightColor='#EBFAF2'
                darkColor='#43CA82'
                onClick={() => this.setState({ type: FD_TYPES.ECONOMY })}
                children='Economy'
              />
              <TypeButton
                active={this.state.type === FD_TYPES.BUSINESS}
                lightColor='#FEF0ED'
                darkColor='#F66D59'
                onClick={() => this.setState({ type: FD_TYPES.BUSINESS })}
                children='Business'
              />
              <TypeButton
                active={this.state.type === FD_TYPES.FIRST_CLASS}
                lightColor='#E6EFFC'
                darkColor='#1861E6'
                onClick={() => this.setState({ type: FD_TYPES.FIRST_CLASS })}
                children='First Class'
              />
            </div>
          )}

          {this.hasStatus(status.HAS_MATCHING_DEALS) && this.renderTable()}

          {this.hasStatus(status.NOT_CACHED) && (
            <React.Fragment>
              <SmallLoader />
              <p className='i'>Just a minute...</p>
            </React.Fragment>
          )}

          {this.hasStatus(status.NO_DEALS) && (
            <p className='i'>No flights currently available</p>
          )}
        </Container>
      </FlightColumn>
    )
  }
}

const Container = styled.div`
  border: 2px solid rgba(21, 60, 107, 0.25);
  border-radius: 12px;
  color: #153c6b;

  padding: 20px;

  a,
  p {
    color: #153c6b;
  }

  .br-top {
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }

  .br-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
`

const TypeButton = styled.button.attrs({
  type: 'button'
})`
  cursor: pointer;
  outline: none;
  padding: 8px 8px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 4px;
  background: ${props => props.lightColor};
  color: ${props => props.darkColor};
  margin-bottom: 0.5rem;
  margin-right: 8px;
  border: 1px solid
    ${props => (props.active ? props.darkColor : props.lightColor)};
`

const FlightColumn = styled.div`
  width: 100%;

  @media only screen and (min-width: 1441px) {
    width: 50%;
  }
`

export default FlightDetails
