import React from 'react'

import { navigate } from 'gatsby'
import styled from 'styled-components'

import { Button } from 'components/Landings/CheapFlightCard'

import colors from 'styles/colors'

import root from 'utils/windowOrGlobal'

function openCreateModal () {
  navigate(root.location.pathname)
}

class CreateEvent extends React.PureComponent {
  render () {
    const { eventID } = this.props
    return (
      <Container show={!!eventID} className='ma3-l center'>
        <div
          className='pa4 br3'
          style={{ background: 'rgba(216, 233, 255, 0.5)' }}
        >
          <p className='b' style={{ fontSize: 18 }}>
            Event Flight Watcher
          </p>
          <p style={{ fontSize: 14 }}>
            Create an event flight tracker for any occasion.
          </p>

          <div>
            <Button
              className='mt4 mw5 db w-100 center fn'
              border={`5px solid ${colors.lightBlueII}`}
              style={{ padding: '18px' }}
              fontWeight='bold'
              backgroundColor={colors.lightBlueI}
              focusBackgroundColor={colors.darkIce}
              children='Create'
              onClick={openCreateModal}
            />
          </div>
        </div>
      </Container>
    )
  }
}

export const Container = styled.div`
  display: none;
  width: 340px;

  @media (min-width: 1440px) {
    display: ${props => props.show && 'block'};
  }
`

export default CreateEvent
