import React from 'react'

import { navigate } from 'gatsby'

import Icon from 'components/Icon'
import { SmallLoader } from 'components/InfiniteList'

import FlightDetails from './_FlightDetails'
import AddAirportModal from './_AddAirportModal'
import { Button } from 'components/Landings/CheapFlightCard'

import get from 'lodash/get'
import styled from 'styled-components'

import colors from 'styles/colors'

import root from 'utils/windowOrGlobal'
import copyToClipboard from 'utils/copy-to-clipboard'

const defaultDestinationImage =
  'https://t2.gstatic.com/images?q=tbn:ANd9GcT9oUsmpcbilr8yAxxZGDCziQV8tBiH0GwSX78BOGmN-aq3E8gv29gwTN8xL-mbHLQXQNc_rQSw'

const resizeImage = url =>
  url
    .replace('image/upload', 'image/upload/w_300,h_200,c_fit')
    .replace('http://', 'https://')

class CopyableText extends React.PureComponent {
  state = {}

  copyToClipboard = () => {
    const { text } = this.props
    copyToClipboard(text)
    this.setState({ copied: true })
  }

  render () {
    const {
      props: { children },
      state: { copied }
    } = this

    return (
      <div className='pointer' onClick={this.copyToClipboard}>
        {children}
        &nbsp;&nbsp;&nbsp;
        <span className='di' style={{ color: '#2F71C5' }}>
          {copied ? 'COPIED' : 'COPY'}
        </span>
      </div>
    )
  }
}

class EventDetails extends React.PureComponent {
  componentDidUpdate ({ event }) {
    const title = get(this.props.event, 'title')

    if (title && title !== event.title) {
      this.props.setMeta({
        title: `Event Flights Watcher - ${title}`,
        description: `Get Cheap Flights for ${title} on StayCircles.com`,
        image: this.destinationImage
      })
    }
  }

  get destinationSubject () {
    const { destination, meta } = this.props.event
    const airport = get(meta, `airportDetails.${destination}.name`)

    return `${destination} - ${airport}`
  }

  get destinationCity () {
    const { destination, meta } = this.props.event
    const destinationCity = get(
      meta,
      `airportDetails.${destination}.regional_city.name`
    )
    const destinationCountry = get(
      meta,
      `airportDetails.${destination}.country.name`
    )

    return `${destinationCity}, ${destinationCountry}`
  }

  get destinationImage () {
    const { destination, meta } = this.props.event

    const cityPictures = get(
      meta,
      `airportDetails.${destination}.city_pictures`,
      []
    )

    const destinationImage = cityPictures.find(i => i.is_primary)

    return destinationImage
      ? resizeImage(destinationImage.url)
      : cityPictures.length > 0
        ? resizeImage(cityPictures[0].url)
        : defaultDestinationImage
  }

  get departDates () {
    return this.props.event.departDates || []
  }

  get returnDates () {
    return this.props.event.returnDates || []
  }

  get prices () {
    return this.props.event.prices || []
  }

  get origins () {
    return this.props.event.origins || []
  }

  openAirportModal = () => {
    const { pathname, search } = root.location
    navigate(`${pathname}${search}&add_airport=true`)
  }

  shareEvent = (evTitle, evUrl) => {
    if (navigator.share) {
      // we can use web share!
      navigator
        .share({
          title: evTitle,
          url: evUrl
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
        .catch(err => {
          console.log(`Couldn't share because of`, err.message)
        })
    } else {
      // provide a fallback here - web share not supported
    }
  }

  navToCreatePage = () => {
    const { pathname } = root.location
    navigate(pathname)
  }

  render () {
    const { event, loading, store, eventID } = this.props
    const { destination, meta } = this.props.event
    const airport = get(meta, `airportDetails.${destination}.name`)

    if (!eventID) return null

    if (loading) {
      return (
        <div className='mh3-l mh2-m w-60-l flex flex-grow justify-center'>
          <SmallLoader />
        </div>
      )
    }

    return (
      <div className='mh3-l mh2-m w-60-l flex flex-grow justify-center'>
        <TopShare>
          <CSEvent onClick={this.navToCreatePage}>
            <CustomH5>Create</CustomH5>
            <CSIcon name='event-create' />
          </CSEvent>
          <AMLogo name='am-logo' />
          {/* remove visibility hidden and fix web share API working for mobile devices - TODO */}
          <CSEvent
            style={{ visibility: 'hidden' }}
            onClick={e =>
              this.shareEvent(
                get(this.props, 'event.eventID', 'undefinedTitle'),
                get(this.props, 'url.href', 'undefinedUrl')
              )
            }
          >
            <CustomH5>Share</CustomH5>
            <CSIcon name='event-share' />
          </CSEvent>
        </TopShare>
        <div className='ma3 w-100'>
          <div className='cf ph1-ns'>
            <div className='pt1 pb2'>
              <p className='f4 mb2 di'>Event Flight Watcher</p>
              <div className='di'>{event.active && <LiveIndicator />}</div>
            </div>
            <p className='b f2 flex flex-row items-center mb2'>{event.title}</p>
            <CopyableText text={`${root.location.href}`}>
              <p className='underline di'>{`${root.location.href}`}</p>
            </CopyableText>
          </div>
          <div className='cf ph1-ns'>
            <div className='fl-l w-100 w-third-l pv3 pr3'>
              <img src={this.destinationImage} className='w-100 br3 shadow-2' />
            </div>
            <div className='fl-l w-100 w-two-thirds-l pv3'>
              <p className='f4 b mb2'>
                {this.destinationCity} ({destination})
              </p>
              <p className='f4'>{airport}</p>
              <div className='flex items-center'>
                <div className='pv3 mr2'>
                  <p className='f5 b mb2'>Arrive</p>
                  {this.departDates.map(departDate => (
                    <p key={departDate} className='f5 mb2'>
                      {departDate}
                    </p>
                  ))}
                </div>
                <div className='pa3-ns pa2 mr2'>
                  <Icon
                    name='mix-shuffle-circle'
                    style={{ width: 42, height: 42 }}
                    color={colors.blackRGBA(0.3)}
                  />
                </div>
                <div className='pv3 mr2'>
                  <p className='f5 b mb2'>Depart</p>
                  {this.returnDates.map(returnDate => (
                    <p key={returnDate} className='f5 mb2'>
                      {returnDate}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className='mv3'
            style={{
              borderBottom: '1px solid rgba(21, 60, 107, 0.25)',
              height: 1
            }}
          />
          <div className='cf ph1-ns pv3 flex flex-row flex-wrap'>
            {this.origins.map(origin => (
              <FlightDetails
                key={origin}
                iata={origin}
                returnDates={this.returnDates}
                departDates={this.departDates}
                flights={
                  this.prices.find(
                    flights => get(flights, '[0].origin') === origin
                  ) || []
                }
                meta={event.meta}
              />
            ))}
          </div>
          <div className='flex justify-center mv3'>
            <Button
              border={`5px solid ${colors.lightBlueII}`}
              fontWeight='bold'
              backgroundColor={colors.lightBlueI}
              focusBackgroundColor={colors.darkIce}
              children='Add Airport'
              onClick={this.openAirportModal}
            />
          </div>
          <BottomPlaceHolder />
          <AddAirportModal
            addOrigin={store.addOrigin}
            origins={event.origins}
          />
          <div
            className='mt4'
            style={{
              borderBottom: '1px solid rgba(21, 60, 107, 0.25)',
              height: 1
            }}
          />
        </div>
        <BottomShare>
          <BSCol flex={0.2}>
            <CustomH5>Adventure Machine</CustomH5>
            <CustomPara>Get the app.</CustomPara>
          </BSCol>
          <BSCol flex={0.3}>
            <a
              target='_blank'
              href='https://adventuremachine.app.link/QjsSvT1LA3'
            >
              <StoreIcon src='https://res.cloudinary.com/staycircles/image/upload/v1572942190/assets/sc_web_landing/download-on-the-app-store-apple_1.png' />
            </a>
          </BSCol>
          <BSCol flex={0.3}>
            <a
              target='_blank'
              href='https://adventuremachine.app.link/6h6wGT3LA3'
            >
              <StoreIcon src='https://res.cloudinary.com/staycircles/image/upload/v1573449692/assets/sc_web_landing/google-play-badge_1_1.png' />
            </a>
          </BSCol>
          <BSCol flex={0.2}>
            <PhoneIcon src='https://res.cloudinary.com/staycircles/image/upload/v1572942136/assets/sc_web_landing/Adventure_Machine_Phone_1.png' />
          </BSCol>
        </BottomShare>
      </div>
    )
  }
}

const LiveIndicator = styled.span.attrs({
  children: 'LIVE'
})`
  display: inline;
  background-color: #ebfaf2;
  color: #43ca82;
  padding: 6px 6px;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 4px;

  @media (max-width: 540px) {
    font-size: 10px;
    padding: 6px 12px;
    min-width: 50px;
  }
`

const TopShare = styled.div`
  @media (max-width: 960px) {
    position: fixed;
  }
  @media (min-width: 961px) {
    display: none;
  }
  top: 0;
  z-index: 10;
  width: 100%;
  height: 60px;
  background-color: #ecf4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const CSEvent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #d8e9ff;
  margin: auto 10px;
  height: 36px;
  padding: 8px;
  border-radius: 4px;
`

const CustomH5 = styled.h5`
  @media (max-width: 400px) {
    font-size: 10px;
  }
  font-size: 12px;
  margin: 0px 6px 3px 0px;
  word-break: initial;
`
const CustomPara = styled.p`
  @media (max-width: 400px) {
    font-size: 10px;
  }
  font-size: 11px;
  margin: 0px;
  word-break: initial;
`

const CSIcon = styled(Icon)`
  @media (max-width: 400px) {
    height: 14px;
  }
  height: 18px;
`

const AMLogo = styled(Icon)`
  @media (max-width: 400px) {
    height: 24px;
  }
  height: 32px;
`

const BottomPlaceHolder = styled.div`
  @media (max-width: 600px) {
    height: 50px;
  }
`
const BottomShare = styled.div`
  @media (max-width: 960px) {
    position: fixed;
  }
  @media (min-width: 961px) {
    display: none;
  }
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 70px;
  background-color: #ecf4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const BSCol = styled.div`
  flex: ${props => props.flex};
  padding-left: 8px;
`

const StoreIcon = styled.img`
  @media (max-width: 400px) {
    height: 27px;
  }
  height: 36px;
`
const PhoneIcon = styled.img`
  margin-top: 80px;
  height: 140px;
`

export default EventDetails
